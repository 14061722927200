<template>
  <PublicApply :post="post"></PublicApply>
</template>
<script>
import PublicApply from './PublicApply'
export default {
  name: 'Appraisal',
  data () {
    return {
      post: {
        service_type: '预约-司法鉴定',
        type: '司法鉴定机构',
        service_name: '司法鉴定预约办理',
        info: '' +
          '<p>1、委托人应当向鉴定机构提供真实、完整、充分的鉴定材料，并对鉴定材料的真实性、合法性负责。<p>' +
          '<p>2、司法鉴定按照法律、法规和规章规定的方式、方法和步骤，遵守和采用相关技术标准和技术规范进行鉴定。</p>' +
          '<p>3、司法鉴定实行鉴定人负责制度。司法鉴定人依法独立、客观、公正进行鉴定，不受任何个人和组织的非法干预。</p>' +
          '<p>4、使用本鉴定文书应当保持其完整性和严肃性。</p>'
      }
    }
  },
  components: {
    PublicApply
  }
}
</script>
<style scoped >
</style>
